<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Usuarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Seguridad</li>
                  <li class="breadcrumb-item active">Usuarios</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Usuarios</h5>
              </div>
              <div class="row p-3 d-flex justify-content-center">
                <fieldset class="well card col-md-9 m-1">
                  <legend class="well-legend text-bold bg-frontera text-light">
                    Datos Personales
                  </legend>
                  <div class="row d-flex justify-content-center">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="type_document">Tipo Documento</label>
                        <select class="form-control form-control-xl" v-model="form.type_document"
                          :class="$v.form.type_document.$invalid ? 'is-invalid' : 'is-valid'">
                          <option value="">Seleccione...</option>
                          <option v-for="tipo in listasForms.tipo_identificacion" :key="tipo.numeracion"
                            :value="tipo.numeracion">
                            {{ tipo.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="n_documento">Numero Identificación</label>
                        <input type="number" class="form-control form-control-xl" id="n_documento"
                          v-model="form.n_documento" :class="$v.form.n_documento.$invalid ? 'is-invalid' : 'is-valid'"
                          @change="documentValidacion()" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="name">Nombre</label>
                        <input type="text" class="form-control form-control-xl" id="name" v-model="form.nombres"
                          :class="$v.form.nombres.$invalid ? 'is-invalid' : 'is-valid'"
                          @input="validarLetra(form.nombres, 1)" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="status">Apellidos</label>
                        <input type="text" class="form-control form-control-xl" id="name" v-model="form.apellidos"
                          :class="$v.form.apellidos.$invalid ? 'is-invalid' : 'is-valid'"
                          @input="validarLetra(form.apellidos, 1)" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" class="form-control form-control-xl" id="email" v-model="form.email"
                          :class="$v.form.email.$invalid ? 'is-invalid' : 'is-valid'" @change="emailValidacion()" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="cel">Celular</label>
                        <input type="number" class="form-control form-control-xl" id="cel" v-model="form.telefono"
                          :class="$v.form.telefono.$invalid ? 'is-invalid' : 'is-valid'" />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="well card col-md-9 m-1">
                  <legend class="well-legend text-bold bg-frontera text-light">
                    Información general
                  </legend>
                  <div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-md-12 mt-4">
                        <div class="form-group">
                          <div class=" alert alert-default-primary border border-primary">
                            <h5>
                              <i class="fas fa-question-circle"></i>
                              ¿Tiene contrato directo con frontera?
                            </h5>
                          </div>
                          <input type="radio" name="pregunta" id="si" value="si" v-model="rd" @input="checkRd">
                          <label for="si">Si</label><br>
                          <input type="radio" name="pregunta" id="no" value="no" v-model="rd" @input="checkRd">
                          <label for="no">No</label>
                        </div>
                      </div>
                      <div class="col-md-12" v-if="rd">
                        <div class="col-md-12">
                          <label for="cel">Nombre Empresa</label>
                          <input type="text" class="form-control form-control-xl" v-model="form.empresa"
                            :class="$v.form.empresa.$invalid ? 'is-invalid' : 'is-valid'" v-if="rd == 'si'" />
                          <input type="text" class="form-control form-control-xl" v-model="form.empresa_tercero"
                            :class="$v.form.empresa_tercero.$invalid ? 'is-invalid' : 'is-valid'" v-else />
                        </div>
                        <div class="col-md-12 mt-4" v-if="rd == 'no'">
                          <div class=" alert alert-default-primary border border-primary">
                            <h5>
                              <i class="fas fa-question-circle"></i>
                              ¿A qué empresa representa, que tenga contrato directo con frontera?
                            </h5>
                          </div>
                          <input type="text" class="form-control form-control-xl" v-model="form.empresa"
                            :class="$v.form.empresa.$invalid ? 'is-invalid' : 'is-valid'" />
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="form-group">
                            <label for="cel">Rol o cargo</label>
                            <input type="text" class="form-control form-control-xl" v-model="form.rol_cargo"
                              :class="$v.form.rol_cargo.$invalid ? 'is-invalid' : 'is-valid'" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="cel">Linea de Negocio</label>
                            <select class="form-control form-control-xl" v-model="form.linea_negocio_id"
                              :class="$v.form.linea_negocio_id.$invalid ? 'is-invalid' : 'is-valid'">
                              <option v-for="linea in listasForms.linea_negocios" :key="linea.id" :value="linea.id">
                                {{ linea.nombre }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="cel">No de contrato</label>
                            <input type="motivo" class="form-control form-control-xl" id="motivo"
                              v-model="form.n_contrato"
                              :class="$v.form.n_contrato.$invalid ? 'is-invalid' : 'is-valid'" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="email_admin">Email Administrador</label>
                            <input type="email" class="form-control form-control-xl" id="email_admin"
                              v-model="form.email_admin"
                              :class="$v.form.email_admin.$invalid ? 'is-invalid' : 'is-valid'"
                              @change="emailAdminValidar()" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="cel">Motivo</label>
                            <textarea name="area" rows="2" cols="40" class="form-control form-control-xl " id="motivo"
                              v-model="form.motivo"
                              :class="$v.form.motivo.$invalid ? 'is-invalid' : 'is-valid'"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="card-body">
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-primary col-md-1" v-show="!$v.form.$invalid" @click="save()"
                    v-if="$store.getters.can('admin.solicitudUsuarios.create') || $store.getters.can('admin.solicitudUsuarios.edit')">
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "UsuarioFronteraForm",
  components: {
    Loading,
  },
  data() {
    return {
      form: {
        id: null,
        empresa: null,
        empresa_tercero: null,
        n_contrato: null,
        linea_negocio_id: null,
        rol_cargo: null,
        email: null,
        email_admin: null,
        motivo: null,
        estado: null,
        nombres: null,
        apellidos: null,
        n_documento: null,
        type_document: null,
        telefono: null,
      },
      error: "",
      accion: "",
      metodo: "",
      rd: "si",
      email: "",
      n_documento: "",
      listasForms: {
        tipo_identificacion: [],
        empresas: [],
        linea_negocios: [],
      },
      id: this.$route.params.id,
      cargando: false,
    };
  },

  validations() {
    if (this.rd == 'si') {
      return {
        form: {
          n_documento: {
            required,
            minLength: minLength(7),
            maxLength: maxLength(13),
          },
          nombres: {
            required,
            minLength: minLength(8),
          },
          email: {
            required,
            email,
          },
          telefono: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
          apellidos: {
            required,
            minLength: minLength(8),
          },
          empresa: {
            required,
          },
          rol_cargo: {
            required,
          },
          motivo: {
            required,
            minLength: minLength(10),
          },
          linea_negocio_id: {
            required,
          },
          n_contrato: {
            required,
          },
          type_document: {
            required,
          },
          email_admin: {
            required,
            email,
          }
        },
      }
    } else if (this.rd == 'no') {
      return {
        form: {
          n_documento: {
            required,
            minLength: minLength(7),
            maxLength: maxLength(13),
          },
          nombres: {
            required,
            minLength: minLength(8),
          },
          email: {
            required,
            email,
          },
          telefono: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
          apellidos: {
            required,
            minLength: minLength(8),
          },
          empresa: {
            required,
          },
          empresa_tercero: {
            required,
          },
          rol_cargo: {
            required,
          },
          motivo: {
            required,
            minLength: minLength(10),
          },
          linea_negocio_id: {
            required,
          },
          n_contrato: {
            required,
          },
          type_document: {
            required,
          },
          email_admin: {
            required,
            email,
          }
        },
      }
    }
  },

  methods: {
    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        if (this.form.empresa_tercero) {
          this.rd = 'no';
        }
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
        this.form.estado = 1;
      }
    },

    validarLetra(palabra, opcion) {
      if (palabra.length > 0) {
        var ascii = palabra.toUpperCase().charCodeAt(palabra.length - 1);
        if (
          !(
            (ascii > 64 && ascii < 91) ||
            ascii == 32 ||
            ascii == 193 ||
            ascii == 201 ||
            ascii == 205 ||
            ascii == 211 ||
            ascii == 218
          )
        ) {
          if (opcion == 1) {
            this.form.name = palabra.slice(0, -1);
          }
        }
      }
    },

    getTiposIdentificacion() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_identificacion = response.data;
      });
    },
    getLineasNegocios() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.linea_negocios = response.data;
      });
    },
    checkRd() {
      this.form.empresa = null;
      this.form.empresa_tercero = null;
    },
    emailValidacion() {
      this.cargando = true;
      let email = this.form.email.split("@");
      axios({
        method: "GET",
        url: "/api/admin/usuarios/emailValidacion",
        params: this.form,
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        if (email[1] == "fronteraenergy.ca") {
          if (this.validar == "si") {
            this.form.funcionario = this.validar;
            this.form.email = null;
            this.$swal({
              icon: "error",
              title: `Ya existe un funcionario con este correo.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        }
      });
    },
    emailAdminValidar() {
      this.cargando = true;
      let email = this.form.email_admin.split("@");
      axios({
        method: "GET",
        url: "/api/admin/usuariosFrontera/emailAdminValidar",
        params: this.form,
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        if (email[1] == "fronteraenergy.ca") {
          if (this.validar == "si") {
            this.form.funcionario = this.validar;            
          } 
        }else {
          this.form.funcionario = this.validar;
          this.form.email_admin = null;
          this.$swal({
            icon: "warning",
            title: `No existe un funcionario con este correo.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      });
    },

    documentValidacion() {
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/admin/usuariosFrontera/documentValidacion",
        params: this.form,
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        if (this.validar == "si") {
          this.form.funcionario = this.validar;
          this.form.n_documento = null;
          this.$swal({
            icon: "error  ",
            title: `Ya existe un funcionario con este numero de documento.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/usuariosFrontera",
          data: this.form,
        })
          .then((response) => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title:
                "la solicitud se creo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            return this.$router.push({
              name: "/Admin/UsuariosFrontera",
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la accion nuevamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/Admin/UsuariosFrontera");
    },
  },
  
  mounted() {
    this.getTiposIdentificacion();
    this.getLineasNegocios();
    this.init();
  },
};

</script>
<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
</style>